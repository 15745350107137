import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  //路由跳转
  {
    path: '/',
    redirect:"/home"
  },
  //首页路由
  {
    path: '/home',
    name: 'home',
    meta:{
      isTabShow:true,
      num:0
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
   
  },
  
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/* 
路由守卫
*/

export default router
